import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
				primary: '#194E76',
				secondary: '#F18324',
        success: "#27AE60",
				warning: '#F2C94C',
        error: '#d61313',
				accent: '#D3D3D3',
        bg: '#F2F7FF',
        mutual_primary: "#F18324",
        background_accent: '#F9E4D2',
      },
    },
  },
});
