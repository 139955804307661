import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "base",
    meta: { requiresAuth: false },
    component: () => import("@/components/layout/Base.vue"),
    children: [
      {
        path: "/",
        name: "home",
        meta: { requiresAuth: false, title: "Home", track_page: false },
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/responsible-lending.html",
        name: "responsible-lending",
        meta: {
          requiresAuth: false,
          title: "Responsible Lending",
          track_page: false,
        },
        component: () => import("../views/ResponsibleLending.vue"),
      },
      {
        path: "/about-us.html",
        name: "about-us",
        meta: { requiresAuth: false, title: "About Us", track_page: false },
        component: () => import("../views/AboutView.vue"),
      },
      {
        path: "/team.html",
        name: "team",
        meta: { requiresAuth: false, title: "Team", track_page: false },
        component: () => import("../views/TeamView.vue"),
      },
      {
        path: "/contact.html",
        name: "contact",
        meta: { requiresAuth: false, title: "Contact", track_page: false },
        component: () => import("../views/ContactView.vue"),
      },
      {
        path: "/blogs",
        name: "blogs",
        meta: { requiresAuth: false, title: "Blogs", track_page: false },
        component: () => import("../views/BlogsView.vue"),
      },
      {
        path: "/career.html",
        name: "career",
        meta: { requiresAuth: false, title: "Career", track_page: false },
        component: () => import("../views/Career.vue"),
      },
      {
        path: "/legal.html",
        name: "legal",
        meta: { requiresAuth: false, title: "Legal", track_page: false },
        component: () => import("../views/LegalView.vue"),
      },
      {
        path: "/Terms&Condition.html",
        name: "TermsAndConditions",
        meta: {
          requiresAuth: false,
          title: "Terms and Conditions",
          track_page: false,
        },
        component: () => import("../views/TermsAndConditions.vue"),
      },
      {
        path: "/Privacy-policy.html",
        name: "Privacy-policy",
        meta: {
          requiresAuth: false,
          title: "Privacy Policy",
          track_page: false,
        },
        component: () => import("../views/PrivacyPolicy.vue"),
      },
      {
        path: "/Instant-loan.html",
        name: "Instant-loan",
        meta: { requiresAuth: false, title: "Instant Loan", track_page: false },
        component: () => import("../views/InstantLoan.vue"),
      },
      {
        path: "/submittion-page",
        name: "submittion-page",
        meta: {
          requiresAuth: false,
          title: "Submittion Page",
          track_page: false,
        },
        component: () => import("../views/SubmittionMessage.vue"),
      },
      {
        path: "/user_already_exists",
        name: "user_exists_message",
        meta: {
          requiresAuth: false,
          title: "User Exists Message",
          track_page: false,
        },
        component: () => import("../views/UserAlreadyExistsPage.vue"),
      },
      {
        path: "/FAQ.html",
        name: "FAQ",
        meta: { requiresAuth: false, title: "FAQ", track_page: false },
        component: () => import("../views/FaqsView.vue"),
      },
      {
        path: "/upload_bank_statement.html",
        name: "upload-bank-statement",
        meta: {
          requiresAuth: false,
          title: "Upload Bank Statement",
          track_page: false,
        },
        component: () => import("../views/UploadBankStatement.vue"),
      },
      {
        path: "/business-loan-login",
        name: "business-loan-login",
        meta: {
          requiresAuth: false,
          title: "Business Loan Login",
          track_page: true,
        },
        component: () => import("../views/BusinessLoanLoginPage.vue"),
        // component: () => import("../views/BusinessLoanRegisterPage.vue"),
      },
      {
        path: "/business-loan-register/:lead_source?",
        name: "business-loan-register",
        meta: {
          requiresAuth: false,
          title: "Business Loan Register",
          track_page: true,
        },
        component: () => import("../views/BusinessLoanRegisterPage.vue"),
      },
      {
        path: "/business-loan",
        name: "Business-Loan",
        meta: { requiresAuth: true, title: "Business Loan", track_page: false },
        component: () => import("../views/BusinessLoanView.vue"),
      },
      {
        path: "/business-loan-info",
        name: "Business-Loan-Info",
        meta: {
          requiresAuth: false,
          title: "Business Loan Info",
          track_page: false,
        },
        component: () => import("../views/BusinessLoanIntro.vue"),
      },
      // {
      //   path: "/business-loan",
      //   name: "Business-Loan",
      //   meta: { requiresAuth: true, title: "", track_page: false },
      //   component: () => import("../views/BusinessLoanView.vue"),
      // },
      {
        path: "/mutual-funds-register",
        name: "mutual-funds-register",
        meta: { requiresAuth: false, title: "LAS Register", track_page: true },
        component: () => import("../views/mutualFundsRegisterPage.vue"),
      },
      {
        path: "/mutual-funds-login",
        name: "mutual-funds-login",
        meta: { requiresAuth: false, title: "LAS Login", track_page: true },
        component: () => import("../views/mutualFundsLogin.vue"),
      },
      {
        path: "/our-lending-partners",
        name: "our-lending-partners",
        meta: {
          requiresAuth: false,
          title: "Our Lending Partners",
          track_page: true,
        },
        component: () => import("../views/OurLendingPartners.vue"),
      },
      {
        path: "/gro-details",
        name: "gro-details",
        meta: {
          requiresAuth: false,
          title: "Grievance Redressal Officer Details",
          track_page: true,
        },
        component: () => import("../views/GrievanceRedressalOfficer.vue"),
      },
      {
        path: "/data-storage-policy",
        name: "data-storage-policy",
        meta: {
          requiresAuth: false,
          title: "Data Storage Policy",
          track_page: true,
        },
        component: () => import("../views/DataStoragePolicy.vue"),
      },
      {
        path: "/business-loan-details",
        name: "business-loan-details",
        meta: {
          requiresAuth: false,
          title: "Busniess Loan Page",
          track_page: true,
        },
        component: () => import("../views/BusinessLoanDetails.vue"),
      },
      {
        path: "/personal-loan-details",
        name: "personal-loan-details",
        meta: {
          requiresAuth: false,
          title: "Personal Loan Page",
          track_page: true,
        },
        component: () => import("../views/PersonalLoanDetails.vue"),
      },
      {
        path: "/personal-loan",
        name: "personal-loan",
        meta: {
          requiresAuth: false,
        },
        component: () => import("../views/personal-loan/index.vue"),
        children: [
          {
            path: "register",
            name: "personal-loan-register",
            meta: {
              requiresAuth: false,
              title: "Personal Loan",
              track_page: true,
            },
            component: () =>
              import("../views/personal-loan/PersonalLoanRegister.vue"),
            beforeEnter: (to, from, next) => {
              const utm_medium = to.query.utm_medium || null;
              const campaign_name = to.query.campaign_name || null;
              sessionStorage.setItem("campaign_name", campaign_name);
              sessionStorage.setItem("utm_medium", utm_medium);
              next();
            },
          },
          {
            path: "otp",
            name: "personal-loan-otp",
            meta: {
              requiresAuth: false,
              title: "Personal Loan",
              track_page: true,
            },
            component: () => import("../views/personal-loan/OtpView.vue"),
          },
          {
            path: "eligible-offers",
            name: "personal-loan-eligible-offers",
            meta: {
              requiresAuth: false,
              title: "Personal Loan",
              track_page: true,
            },
            component: () =>
              import("../views/personal-loan/EligibleOffersList.vue"),
          },
          {
            path: "login",
            name: "personal-loan-login",
            meta: {
              requiresAuth: false,
              title: "Personal Loan",
              track_page: true,
            },
            component: () => import("../views/PersonalLoanLogin.vue"),
          },
        ],
      },
      {
        path: "/dlg-disclosure",
        name: "dlg-disclosure",
        meta: {
          requiresAuth: false,
          title: "DLG Disclosure",
          track_page: true,
        },
        component: () => import("../views/DlgDisclosure.vue"),
      },
    ],
  },
  {
    path: "/borrower_consent.html",
    name: "Borrower Consent",
    meta: { requiresAuth: false, title: "Borrower Consent", track_page: false },
    component: () => import("../views/BorrowersConsentView.vue"),
  },
  {
    path: "/third_party_consent.html",
    name: "Third Party Consent",
    meta: {
      requiresAuth: false,
      title: "Third Party Consent",
      track_page: false,
    },
    component: () => import("../views/ThirdPartyConsentView.vue"),
  },
  {
    path: "/cashpo_grievance_policy.html",
    name: "Cashpo Grievance Policy",
    meta: {
      requiresAuth: false,
      title: "Cashpo Grievance Policy",
      track_page: false,
    },
    component: () => import("../views/GrievancePolicy.vue"),
  },
  {
    path: "/privacy_policies.html",
    name: "Privacy Policies",
    meta: { requiresAuth: false, title: "Privacy Policies", track_page: false },
    component: () => import("../views/PrivacyPolicyView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

// router.beforeEach((to, from, next) => {
//   let access_token = sessionStorage.getItem("token");
//   if (to.meta.requiresAuth == true && !access_token) {
//     next({
//       name: "login",
//     });
//   } else next();
// });
router.beforeEach((to, from, next) => {
  // let access_token = sessionStorage.getItem("token");
  if (to.fullPath == "/personal-loan-register") {
    next({
      name: "personal-loan-register",
    });
  }
  let business_loan_token = sessionStorage.getItem("access_token");
  if (to.meta.requiresAuth == true && !business_loan_token) {
    next({
      name: "business-loan-login",
    });
  } else next();
});

export default router;
