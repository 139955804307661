export const namespaced = true;

export const state = {
  text: "",
  color: "",
  flag: false,
  timeout: 1000,
  can_close: true,
  show_on_top: true,
};

export const mutations = {
  SET_SNACKBAR(state, { flag, text, color, timeout, can_close, show_on_top }) {
    state.flag = flag;
    state.text = text;
    state.color = color;
    state.timeout = timeout;
    state.can_close = can_close;
    state.show_on_top = show_on_top;
  },
};

export const actions = {
  setSnackbar({ commit }, items) {
    commit("SET_SNACKBAR", items);
  },
};
export const getters = {
  getSnackBar: (state) => {
    return state;
  },
};
