import Vue from "vue";
import Vuex from "vuex";

import * as snackbar from "./modules/snackbar";
import * as login_data from "./modules/login-data";
import business_loan_data from "./modules/business_loan_data";
import * as las_data from "./modules/las";
import * as personal_loan from "./modules/personal-loan";
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    snackbar,
    login_data,
    business_loan_data,
    las_data,
    personal_loan,
  },
  state: {
    is_production: true,
    page_loader: false,
    search_text: "",
    // token:localStorage.getItem("token") || null,
    token: sessionStorage.getItem("token") || null,
    user_type: sessionStorage.getItem("user_type") || null,
    permission_list:
      JSON.parse(sessionStorage.getItem("permission_list")) || [],
    // user_type:localStorage.getItem("user_type") || null,
    warehouse: JSON.parse(localStorage.getItem("warehouse")) || null,
  },
  categories: [],
  getters: {},
  mutations: {},
  actions: {},
});
