export const namespaced = true;

export const state = {
  mobile_no: "",
  // offer_list_details: {
  //   is_offers_list_loading: false,
  //   is_eligible: true,
  //   is_loan_exists: true, // not being used
  //   is_loan_amount_available: false,
  //   is_offers_available: false,
  // },
  // offer_list_details: null,
  offer_list_details: {
    is_loading: true,
    is_offer_available: false,
    is_eligible: true,
    is_logged_in: false,
  },
  eligibility_form: {},
};

export const mutations = {
  SET_MOBILE_NUMBER(state, payload) {
    state.mobile_no = payload;
  },
  SET_OFFER_LIST_DETAILS(
    state,
    { is_loading, is_offer_available, is_eligible, is_logged_in }
  ) {
    state.offer_list_details.is_loading = is_loading;
    state.offer_list_details.is_offer_available = is_offer_available;
    state.offer_list_details.is_eligible = is_eligible;
    state.offer_list_details.is_logged_in = is_logged_in;
  },
  SET_ELIGIBILITY_FORM(state, payload) {
    state.eligibility_form = payload;
  },
};

export const actions = {
  setMobileNumber({ commit }, payload) {
    commit("SET_MOBILE_NUMBER", payload);
  },
  setOfferListDetails({ commit }, payload) {
    commit("SET_OFFER_LIST_DETAILS", payload);
  },
  setEligibilityForm({ commit }, payload) {
    commit("SET_ELIGIBILITY_FORM", payload);
  },
};
export const getters = {
  getMobileNumber: (state) => {
    return state.mobile_no;
  },
  getOfferListDetails: (state) => {
    return state.offer_list_details;
  },
  getEligibilityForm: (state) => {
    return state.eligibility_form;
  },
};
