export const namespaced = true;

export const state = {
  mobile_no: "",
};

export const mutations = {
  SET_MOBILE(state, value) {
    state.mobile_no = value;
  },
};

export const actions = {
  setMobile({ commit }, payload) {
    commit("SET_MOBILE", payload);
  },
};
export const getters = {
  getMobile: (state) => {
    return state.mobile_no;
  },
};
