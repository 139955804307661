export const namespaced = true;

export const state = {
  token: "",
  bank_statement_status: "",
  bank_statement_redirect_link: "",
};

export const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_BANK_STATEMENT_STATUS(state, payload) {
    state.bank_statement_status = payload;
  },
  SET_BANK_STATEMENT_REDIRECT_LINK(state, payload) {
    state.bank_statement_redirect_link = payload;
  },
};

export const actions = {
  setToken({ commit }, payload) {
    commit("SET_TOKEN", payload);
  },
  setBankStatementStatus({ commit }, payload) {
    commit("SET_BANK_STATEMENT_STATUS", payload);
  },
  setBankStatementRedirectLink({ commit }, payload) {
    commit("SET_BANK_STATEMENT_REDIRECT_LINK", payload);
  },
};
export const getters = {
  getToken: (state) => {
    return state.token;
  },
  getBankStatementStatus: (state) => {
    return state.bank_statement_status;
  },
  getBankStatementRedirectLink: (state) => {
    return state.bank_statement_redirect_link;
  },
};
