export default {
  namespaced: true,
  state: {
    stepper_value: 1,
    user_already_exists: "",
    login_mobile: "",
    is_mobile_disabled: false,
    is_first_register: false,
    profile_status: "",
    basic_details: {
      loan_amount: "",
      first_name: "",
      last_name: "",
      email: "",
      entity_type: "",
      bussines_age: "",
      turnover: "",
      loan_purpose: "",
      bussines_registration: "",
      temp_bussines_registration: "",
      other_bussines_registration: "",
      residence: "",
      work_experience: "",
    },
    personal_details: {
      fathers_name: null,
      mothers_name: null,
      dob: null,
      family_members: null,
      education: null,
      current_address_details: null,
      permanent_address_details: null,
      current_landmark: null,
      permanent_landmark: null,
      current_area: null,
      permanent_area: null,
      current_pincode: null,
      permanent_pincode: null,
      current_city: null,
      permanent_city: null,
      current_state: null,
      permanent_state: null,
      no_of_years_in_current_city: null,
      no_of_years_in_current_address: null,
    },
    sole_proprietor_file_type: {
      image: "",
      pancard: "",
      front_adhar: "",
      back_adhar: "",
      front_back_adhar: "",
    },
    partnership_file_type: {
      image: "",
      pancard: "",
      front_adhar: "",
      back_adhar: "",
      front_back_adhar: "",
    },
    company_file_type: {
      image: "",
      pancard: "",
      front_adhar: "",
      back_adhar: "",
      front_back_adhar: "",
    },
  },
  getters: {
    getBasicDetails: (state) => state.basic_details,
    getPersonalDetails: (state) => state.personal_details,
    getSoleProprietorFileType: (state) => state.sole_proprietor_file_type,
    getPartnershipFileType: (state) => state.partnership_file_type,
    getCompanyFileType: (state) => state.company_file_type,
    getStepperValue: (state) => state.stepper_value,
    getUserExistsMsg: (state) => state.user_already_exists,
    getLoginMobile: (state) => state.login_mobile,
    getIsMobileDisabled: (state) => state.is_mobile_disabled,
    getIsFirstRegister: (state) => state.is_first_register,
    getProfileStatus: (state) => state.profile_status,
  },
  mutations: {
    SET_STEPPER_VALUE(state, obj) {
      state.stepper_value = obj.stepper_value;
    },
    SET_BASIC_DETAILS(state, obj) {
      state.basic_details.loan_amount = obj.loan_amount;
      state.basic_details.first_name = obj.first_name;
      state.basic_details.last_name = obj.last_name;
      state.basic_details.email = obj.email;
      state.basic_details.entity_type = obj.entity_type;
      state.basic_details.bussines_age = obj.bussines_age;
      state.basic_details.turnover = obj.turnover;
      state.basic_details.loan_purpose = obj.loan_purpose;
      state.basic_details.bussines_registration = obj.bussines_registration;
      state.basic_details.other_bussines_registration =
        obj.other_bussines_registration;
      state.basic_details.residence = obj.residence;
      state.basic_details.work_experience = obj.work_experience;
    },
    SET_PERSONAL_DETAILS(state, obj) {
      state.personal_details.family_members = obj.family_members;
      state.personal_details.fathers_name = obj.fathers_name;
      state.personal_details.mothers_name = obj.mothers_name;
      state.personal_details.education = obj.education;
      state.personal_details.current_address_details =
        obj.current_address_details;
      state.personal_details.current_landmark = obj.current_landmark;
      state.personal_details.current_area = obj.current_area;
      state.personal_details.current_pincode = obj.current_pincode;
      state.personal_details.current_city = obj.current_city;
      state.personal_details.current_state = obj.current_state;
      state.personal_details.dob = obj.dob;
      state.personal_details.permanent_address_details =
        obj.permanent_address_details;
      state.personal_details.permanent_landmark = obj.permanent_landmark;
      state.personal_details.permanent_area = obj.permanent_area;
      state.personal_details.permanent_pincode = obj.permanent_pincode;
      state.personal_details.permanent_city = obj.permanent_city;
      state.personal_details.permanent_state = obj.permanent_state;
      state.personal_details.no_of_years_in_current_city =
        obj.no_of_years_in_current_city;
      state.personal_details.no_of_years_in_current_address =
        obj.no_of_years_in_current_address;
    },
    SET_SOLEPROPRIETOR_FILETYPE(state, obj) {
      state.sole_proprietor_file_type.image = obj.image;
      state.sole_proprietor_file_type.pancard = obj.pancard;
      state.sole_proprietor_file_type.front_adhar = obj.front_adhar;
      state.sole_proprietor_file_type.back_adhar = obj.back_adhar;
      state.sole_proprietor_file_type.front_back_adhar = obj.front_back_adhar;
    },
    SET_PARTNERSHIP_FILETYPE(state, obj) {
      state.partnership_file_type.image = obj.image;
      state.partnership_file_type.pancard = obj.pancard;
      state.partnership_file_type.front_adhar = obj.front_adhar;
      state.partnership_file_type.back_adhar = obj.back_adhar;
      state.partnership_file_type.front_back_adhar = obj.front_back_adhar;
    },
    SET_COMPANY_FILETYPE(state, obj) {
      state.company_file_type.image = obj.image;
      state.company_file_type.pancard = obj.pancard;
      state.company_file_type.front_adhar = obj.front_adhar;
      state.company_file_type.back_adhar = obj.back_adhar;
      state.company_file_type.front_back_adhar = obj.front_back_adhar;
    },
    SET_USER_ALREADY_EXIST_MSG(state, obj) {
      state.user_already_exists = obj;
    },
    CLEAR_ALL_DATA(state) {
      state.basic_details.loan_amount = "";
      state.basic_details.first_name = "";
      state.basic_details.last_name = "";
      state.basic_details.email = "";
      state.basic_details.entity_type = "";
      state.basic_details.bussines_age = "";
      state.basic_details.turnover = "";
      state.basic_details.loan_purpose = "";
      state.basic_details.bussines_registration = "";
      state.basic_details.other_bussines_registration = "";
      state.basic_details.residence = "";
      state.basic_details.work_experience = "";

      state.personal_details.fathers_name = "";
      state.personal_details.mothers_name = "";
      state.personal_details.dob = "";
      state.personal_details.family_members = "";
      state.personal_details.education = "";
      state.personal_details.current_address_details = "";
      state.personal_details.permanent_address_details = "";
      state.personal_details.current_landmark = "";
      state.personal_details.permanent_landmark = "";
      state.personal_details.current_area = "";
      state.personal_details.permanent_area = "";
      state.personal_details.current_pincode = "";
      state.personal_details.permanent_pincode = "";
      state.personal_details.current_city = "";
      state.personal_details.permanent_city = "";
      state.personal_details.current_state = "";
      state.personal_details.permanent_state = "";
      state.personal_details.no_of_years_in_current_city = "";
      state.personal_details.no_of_years_in_current_address = "";

      state.sole_proprietor_file_type.image = "";
      state.sole_proprietor_file_type.pancard = "";
      state.sole_proprietor_file_type.front_adhar = "";
      state.sole_proprietor_file_type.back_adhar = "";
      state.sole_proprietor_file_type.front_back_adhar = "";

      state.partnership_file_type.image = "";
      state.partnership_file_type.pancard = "";
      state.partnership_file_type.front_adhar = "";
      state.partnership_file_type.back_adhar = "";
      state.partnership_file_type.front_back_adhar = "";

      state.company_file_type.image = "";
      state.company_file_type.pancard = "";
      state.company_file_type.front_adhar = "";
      state.company_file_type.back_adhar = "";
      state.company_file_type.front_back_adhar = "";

      state.user_already_exists = "";
      state.login_mobile = "";
      state.is_mobile_disabled = false;
      state.is_first_register = false;
      state.profile_status = "";
      state.stepper_value = 1;
    },
    SET_LOGIN_MOBILE(state, value) {
      state.login_mobile = value;
    },
    SET_IS_MOBILE_DISABLED(state, value) {
      state.is_mobile_disabled = value;
    },
    SET_IS_FIRST_REGISTER(state, value) {
      state.is_first_register = value;
    },
    SET_PROFILE_STATUS(state, value) {
      state.profile_status = value;
    },
    SET_REGISTERATION_DATA(state, obj) {
      state.basic_details.first_name = obj.first_name;
      state.basic_details.last_name = obj.last_name;
      state.basic_details.entity_type = obj.entity_type;
      state.basic_details.bussines_age = obj.bussines_age;
      state.basic_details.turnover = obj.turnover;
    },
  },
  actions: {
    setBasicDetails({ commit }, obj) {
      commit("SET_BASIC_DETAILS", obj);
    },
    setPersonalDetails({ commit }, obj) {
      commit("SET_PERSONAL_DETAILS", obj);
    },
    setSolePropreitorFileType({ commit }, obj) {
      commit("SET_SOLEPROPRIETOR_FILETYPE", obj);
    },
    setPartnershipFileType({ commit }, obj) {
      commit("SET_PARTNERSHIP_FILETYPE", obj);
    },
    setCompanyFileType({ commit }, obj) {
      commit("SET_COMPANY_FILETYPE", obj);
    },
    setStepperValue({ commit }, obj) {
      commit("SET_STEPPER_VALUE", obj);
    },
    setUserAlreadyExists({ commit }, obj) {
      commit("SET_USER_ALREADY_EXIST_MSG", obj);
    },
    clearAllData({ commit }) {
      commit("CLEAR_ALL_DATA");
    },
    setLoginMobile({ commit }, value) {
      commit("SET_LOGIN_MOBILE", value);
    },
    setIsMobileDisabled({ commit }, value) {
      commit("SET_IS_MOBILE_DISABLED", value);
    },
    setIsFirstRegister({ commit }, value) {
      commit("SET_IS_FIRST_REGISTER", value);
    },
    setRegistrationData({ commit }, value) {
      commit("SET_REGISTERATION_DATA", value);
    },
    setProfileStatus({ commit }, value) {
      commit("SET_PROFILE_STATUS", value);
    },
  },
};
